import showAlert from "../utils/ShowAlert";

// export const baseUrl = "http://localhost:8088"; // Spring Boot backend URL
// export const baseUrl = "http://147.79.118.151:8088"; // Spring Boot backend URL
export const baseUrl = "https://backend.sendnous.com";

export default async function ajax(
  url,
  requestMethod,
  jwt,
  requestBody,
  isVoid = false
) {
  const fetchData = {
    method: requestMethod,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (jwt) {
    fetchData.headers.Authorization = `Bearer ${jwt}`;
  }

  if (requestBody) {
    fetchData.body = JSON.stringify(requestBody);
  }

  // console.log(`${url}, ${requestMethod}, ${jwt}, ${JSON.stringify(requestBody)}, ${isVoid}`);

  try {
    const response = await fetch(`${baseUrl}${url}`, fetchData);
    if (response.ok) {
      if (isVoid) {
        return "void";
      } else if (requestMethod === "DELETE") {
        showAlert("Suppression effectuée avec succès !", "success");
        return "delete";
      } else {
        const data = await response.json();
        return data;
      }
    } else if (requestMethod === "DELETE") {
      const data = await response.json();
      if(data && data.offersNumber && data.offersNumber){
        showAlert("Veuillez supprimer les entites affiliées", "error");
      }
      return null;
    } else if (response.status === 400) {
      const errorMessage = response.headers.get("Error-Message");
      console.error(await response.text());
      showAlert(errorMessage, "error");
      return null;
    } else if (response.status === 403) {
      console.error(await response.text());
      // showAlert(
      //   "Accès refusé: Vous n'avez pas le droit d'accéder à cette ressource.",
      // 'error'
      // );
      window.location.href = "/403";
      return null; // Return null as an indication of error
    } else {
      console.error(`Server responded with status ${response.status}`);
    }
  } catch (error) {
    console.error("Error making AJAX request:", error);
    // throw error;
  }
}

// else if (response.status === 404) {
//   return <NotFoundPage />;
// }
