import React, { useEffect, useState } from 'react'
import ajax from '../services/ajax';
import { useJwt } from '../utils/UserProvider';
import Authentication from '../components/user_components/Auth/Authentication';

const PrivateRoute = ({ setLoading, children }) => {
  const { jwt } = useJwt();
  const [isValid, setIsValid] = useState(null);
  const [openLogin, setOpenLogin] = useState(true);
  const [openRegister, setOpenRegister] = useState(false);

  const handleLoginOpen = () => {
    setOpenLogin(true);
    setOpenRegister(false);
  };

  const handleRegisterOpen = () => {
    setOpenRegister(true);
    setOpenLogin(false);
  };

  const handleClose = () => {
    setOpenLogin(false);
    setOpenRegister(false);
    window.location.href = "/";
  };

  useEffect(() => {
    const validateToken = async () => {
      setLoading(true);
      if (!jwt) {
        setIsValid(false);
        setLoading(false);
        return;
      }

      try {
        const response = await ajax(`/api/auth/validate?token=${jwt}`, 'GET', jwt);
        setIsValid(response);
        setLoading(false);
      } 
      catch (error) {
        console.error('Error validating token:', error);
        localStorage.removeItem('jwt');
        setIsValid(false);
      }
      finally{
        setLoading(false);
      }
      setLoading(false);
    };

    validateToken();
  }, [jwt, setLoading]);

  if (isValid === false) {
    return [
      // showAlert("Veuillez vous connecter pour continuer!!!", 'warning'),
      <Authentication setLoading={setLoading} openLogin={openLogin} openRegister={openRegister} handleLoginOpen={handleLoginOpen}
        handleRegisterOpen={handleRegisterOpen} handleClose={handleClose} />,
    ];
  }

  return <>{children}</>;
};

export default PrivateRoute;