import {
  Button, Card, CardHeader, CardContent,
  CssBaseline, Divider, Box
} from '@mui/material';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MainDrawer, DrawerHeader } from "../../public_components/Layouts/Drawer";
import NavBar from "../../public_components/Layouts/NavBar";
import { Delete, Edit } from '@mui/icons-material';
import UserCreateDialog from './UserCreateDialog';
import UserUpdateDialog from './UserUpdateDialog';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';
import { deleteUser, getUsers } from '../../../services/userService';
import { roleLabel } from '../../../data/userData';
import { useJwt } from '../../../utils/UserProvider';

const ManageUsers = ({ setLoading }) => {
  const { jwt } = useJwt();
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  const [users, setUsers] = useState([]);
  const userIdRef = useRef();

  const [openCreateUser, setOpenCreateUser] = useState(false);
  const handleClickCreateUser = () => {
    setOpenCreateUser(true);
  };

  const [openUpdateUser, setOpenUpdateUser] = useState(false);
  const handleClickUpdateUser = (id) => {
    userIdRef.current = id;
    setOpenUpdateUser(true);
  };

  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const handleClickDeleteUser = (id) => {
    userIdRef.current = id;
    setOpenDeleteUser(true);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      const adsData = await getUsers(jwt);
      if (adsData !== null)
        setUsers(adsData);
      setLoading(false);
    };

    if (jwt) {
      fetchUsers();
    }
  }, [jwt, setLoading])

  const columns = useMemo(() => [
    {
      field: 'actions',
      headerName: "Actions",
      type: 'actions',
      renderCell: (params) =>
        <Box
          sx={{
            m: 1,
            position: 'relative',
          }}
        >
          <GridActionsCellItem
            label="Editer"
            icon={<Edit />}
            onClick={(e) => {
              e.preventDefault();
              handleClickUpdateUser(params.id);
            }}
          />
          <GridActionsCellItem
            label="Supprimer"
            color='error'
            icon={<Delete />}
            onClick={(e) => {
              e.preventDefault();
              handleClickDeleteUser(params.id);
            }}
          />
        </Box>
    },
    {
      field: 'id',
      headerName: "Id",
      width: 60,
    },
    {
      field: 'active',
      headerName: "Actif",
      width: 60,
      renderCell: (params) => params.row.active === true ? "Actif" : "Inactif",
    },
    {
      field: 'username',
      headerName: "Nom d'utilisateur",
    },
    {
      field: 'role',
      headerName: "Role",
      width: 120,
      renderCell: (params) => params.row.role && roleLabel(params.row.role),
    },
    {
      field: 'phone',
      headerName: "Téléphone",
      width: 150
    },
    {
      field: 'email',
      headerName: "Email",
      width: 200
    },
    {
      field: 'lastName',
      headerName: "Nom",
    },
    {
      field: 'firstName',
      headerName: "Prénom",
    },
  ], []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 3, width: '100%' }}>
        <DrawerHeader />
        <Card
          sx={{
            borderRadius: 3,
            px: 2,
            py: 2,
            marginBottom: 2,
            width: '100%',
            minHeight: '400px',
          }}
        >
          <CardHeader title="Gestion des Utilisateurs"
            sx={{
              marginBottom: 2,
            }}
          />
          <Divider />
          <CardContent>

            <Button
              variant="contained"
              onClick={() => {
                // table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                handleClickCreateUser();
              }}
              sx={{ textAlign: 'center', my: 3, }}
            >
              Ajouter un utilisateur
            </Button>
            <DataGrid
              columns={columns}
              autoHeight
              rows={users}
              initialState={{
                ...users.initialState,
                pagination: { paginationModel: { pageSize: pageSize } },
                sorting: {
                  sortModel: [{ field: 'id', sort: 'desc' }],
                },
              }}
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 25]}
              rowHeight={70}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                },
              }}
              sx={{
                // '--DataGrid-overlayHeight': '300px',
                border: 'none',
                width: '100%',
                mb: 5,
              }}
            />
          </CardContent>
        </Card >
      </Box>
      {openCreateUser &&
        <UserCreateDialog setLoading={setLoading} open={openCreateUser} setOpen={setOpenCreateUser} setUsers={setUsers} />
      }
      {userIdRef.current &&
        <UserUpdateDialog setLoading={setLoading} userId={userIdRef.current} openUpdateUser={openUpdateUser} setOpenUpdateUser={setOpenUpdateUser} setUsers={setUsers} />
      }
      {userIdRef.current &&
        <DeleteDialog setLoading={setLoading} open={openDeleteUser} setOpen={setOpenDeleteUser} id={userIdRef.current} deleteMethod={deleteUser} setData={setUsers} />
      }
    </Box>
  )
}

export default ManageUsers