import {
  Avatar, Box, Card, CardContent, CardHeader, Chip, CssBaseline, Divider, Stack, Typography, Button
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { deleteOffer, getOffers } from '../../../services/offerService';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { statusColor, statusLabel, status_array } from '../../../data/offerData';
import { adTypes, typeLabel } from '../../../data/adData';
import { useNavigate } from 'react-router-dom';
import NavBar from '../../public_components/Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import SelectFilter from '../../user_components/SelectFilter/SelectFilter';
import dayjs from 'dayjs';
import OfferEditDialog from './OfferEditDialog';
import { useJwt } from '../../../utils/UserProvider';
import DeleteDialog from '../../public_components/Layouts/DeleteDialog';


const ManageOffers = ({ setLoading }) => {
  const { jwt } = useJwt();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(5);

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const offerIdRef = useRef();

  const [offers, setOffers] = useState([]);

  useEffect(() => {
    const fetchOffers = async () => {
      setLoading(true);
      const responseData = await getOffers(jwt);
      if (responseData !== null) {
        setOffers(responseData);
      }
      setLoading(false);
    };
    if (jwt) {
      fetchOffers();
    }
  }, [jwt, setLoading]);

  const handleOpenEdit = (id) => {
    offerIdRef.current = id;
    setOpenEdit(true);
  }

  const handleClickDeleteOffer = (id) => {
    offerIdRef.current = id;
    setOpenDelete(true);
  };

  const adTypeFilter = {
    label: 'Type',
    value: 'ad',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return (params) => {
        const adType = params.row.ad ? params.row.ad.type : null;
        return adType === filterItem.value;
      };
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: adTypes,
    },
  };

  const statusFilter = {
    label: '=',
    value: 'status', // Assuming 'status' is the field in your data
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value) return null;
      return ({ value }) => value === filterItem.value;
    },
    InputComponent: SelectFilter,
    InputComponentProps: {
      options: status_array,
    },
  };


  const columns = useMemo(() => [
    {
      field: 'actions',
      headerName: "Actions",
      type: 'actions',
      width: 300,
      renderCell: (params) => (
        <Box
          sx={{
            m: 1,
            position: 'relative',
            display: 'flex',
            gap: '5px'
          }}
        >
          <Button
            variant="outlined"
            color='info'
            size='small'
            startIcon={<Visibility />}
            onClick={() => {
              navigate(`/user/ads/${params.row.ad.id}`);
            }}
          >
            Voir l'annonce
          </Button>
          {params.row.status &&
            (
              (params.row.status !== 'CLOSED' && params.row.status !== 'PUBLISHED')
              ||
              (
                params.row.status === 'PUBLISHED' &&
                params.row.customer === null &&
                params.row.offersNumber > 0
              )
            )
            &&
            <Button
              variant="outlined"
              size='small'
              startIcon={<Edit />}
              onClick={(e) => {
                e.preventDefault();
                handleOpenEdit(params.id);
              }}
              sx={{
                color: 'var(--primary)'
              }}
            >
              Editer
            </Button>
          }
          {/* {params.row.offerStatus !== 'ACCEPTED' && */}
            <Button
              variant="outlined"
              color='error'
              size='small'
              startIcon={<Delete />}
              onClick={(e) => {
                e.preventDefault();
                handleClickDeleteOffer(params.id);
              }}
            >
              Supprimer
            </Button>
          {/* } */}
        </Box>
      )
    },
    {
      field: 'id',
      headerName: "Id",
      width: 60,
    },
    {
      field: 'offerStatus',
      headerName: "Statut",
      width: 140,
      filterOperators: [statusFilter],
      renderCell: (params) => (
        <Chip
          label={
            statusLabel(params.row.offerStatus)
          }
          variant="outlined"
          color={statusColor(params.row.offerStatus)}
        />
      ),
    },
    {
      field: 'bidder',
      headerName: "Candidat",
      width: 100,
      renderCell: (params) => params.row.bidder?.username
    },
    {
      field: 'ad',
      headerName: "Annonce Répondue",
      width: 350,
      filterOperators: [adTypeFilter],
      valueGetter: (params) => params.row.ad.id,
      renderCell: (params) => {
        const offer = params.row;
        return (
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            <Stack textAlign='center' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Typography variant='body2' component='span'>
                {offer.ad.id}
              </Typography>
              <Chip
                avatar={<Avatar src={params.row.ad.picture} />}
                label={
                  typeLabel(params.row.ad.type)
                }
                variant="outlined"
              />
              <Typography variant='body2' component='span'>
                {offer.ad.publisher && offer.ad.publisher.username}
              </Typography>
              <Typography variant='body2' component='span'>
                {offer.ad.departureRelay && offer.ad.departureRelay.city} à {offer.ad.arrivalRelay && offer.ad.arrivalRelay.city}
              </Typography>
            </Stack>
            <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>

            </Stack>
          </Stack>
        )
      },
    },
    {
      field: 'luggageNumber',
      headerName: "Bagages",
      width: 60,
      renderCell: (params) =>
        params.row.ad.type === 'SALE' &&
        params.row.luggageNumber
    },
    {
      field: 'luggageDescription',
      headerName: "Description",
    },
    {
      field: 'offerContents',
      headerName: "Contenu",
      renderCell: (params) => {
        const offer = params.row;
        return (
          <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {offer.offerContents && offer.offerContents.map((content) =>
              <Typography key={content.id} variant='body2' component='span'>
                {content.designation}
              </Typography>
            )}
          </Stack>
        )
      },
    },
    {
      field: 'depositDeadline',
      headerName: "Délai de dépôt",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD HH:MM') : '',
    },
    {
      field: 'departureDate',
      headerName: "Départ",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : '',
    },
    {
      field: 'arrivalDate',
      headerName: "Arrivée",
      valueFormatter: ({ value }) => value ? dayjs(value).format('YYYY-MM-DD') : '',
    },
    {
      field: 'Escales',
      headerName: "stopoversDetails",
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
      <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 3, width: '100%' }}>
        <DrawerHeader />
        <Card
          sx={{
            borderRadius: 3,
            px: 2,
            py: 2,
            marginBottom: 2,
            width: '95%',
            minHeight: '400px',
          }}
        >
          <CardHeader title="Gestion des Offres"
            sx={{ marginBottom: 2 }}
          />
          <Divider />
          <CardContent>
            <DataGrid
              columns={columns}
              autoHeight
              rows={offers}
              initialState={{
                ...offers.initialState,
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterExcludeHiddenColumns: true,
                  },
                },
                pagination: { paginationModel: { pageSize: pageSize } },
                sorting: {
                  sortModel: [{ field: 'ad', sort: 'desc' }],
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              rowHeight={70}
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  printOptions: { disableToolbarButton: true },
                  csvOptions: { disableToolbarButton: true },
                },
              }}
              getRowSpacing={getRowSpacing}
              rowSpacingType="border"
              sx={{
                border: 'none',
                width: '100%',
                mb: 5,
                '& .MuiDataGrid-row': { borderTopColor: 'white', borderTopStyle: 'solid' }
              }}
            />
          </CardContent>
        </Card >
      </Box>
      {offerIdRef.current && [
        <OfferEditDialog setLoading={setLoading} offerId={offerIdRef.current} openEdit={openEdit} setOpenEdit={setOpenEdit} setOffers={setOffers} />
        ,
        <DeleteDialog setLoading={setLoading} open={openDelete} setOpen={setOpenDelete} id={offerIdRef.current} deleteMethod={deleteOffer} setData={setOffers} />
      ]
      }
    </Box>
  );
}

export default ManageOffers