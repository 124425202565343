import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getRelays } from '../../../services/relayService';
import { searchAds } from '../../../services/adService';
import { useNavigate } from 'react-router-dom';

export default function Header({ setLoading }) {
    const [relays, setRelays] = useState([]);
    const navigate = useNavigate();

    const [adSearchRequest, setAdSearchRequest] = useState({
        type: '',
        departureRelay: null,
        arrivalRelay: null,
    })
    const updateAdSearch = (prop, value) => {
        setAdSearchRequest(prevAd => ({
            ...prevAd,
            [prop]: value
        }));
    }

    useEffect(() => {
        const fetchRelays = async () => {
            setLoading(true);
            const relaysData = await getRelays();
            setRelays(relaysData);
            setLoading(false);
        };
        fetchRelays();
    }, [setLoading]);

    const handleSubmit = async () => {
        setLoading(true);
        const ads = await searchAds(adSearchRequest);
        if (ads !== null) {
            const adsPublished = [];
            ads.forEach(ad => {
                if (ad.status === 'PUBLISHED') {
                    adsPublished.push(ad);
                }
            });
            localStorage.setItem('adsSearch', JSON.stringify(adsPublished));
            window.location.href = `/ads?ads-data=${encodeURIComponent(adsPublished)}`;
        }
        setLoading(false);
    };

    return (
        <div className="bg-white p-0">
            <div className="row flex-col-reverse md:flex-row">
                <div className="md:col-12 p-5 lg:mt-5">
                    <Typography variant='h5' sx={{ mb: 2 }}>
                        Expédiez ou monétisez vos kilos d'avion <span className="text-primary">simplement !</span>
                    </Typography>
                    <Typography variant='body1' sx={{ mb: 2 }}>
                        Avec SendNous, vous pouvez vendre ou acheter des kilos de bagages d'avion inutilisés en provenance ou à destination du Maroc et/ou du Cameroun. <br />
                        Connectez-vous et créez vos annonces en quelques clics. C'est simple, rapide et économique.
                        {/* Avec SendNous, vous pouvez envoyer vos bagages par avion ou vendre vos kilos de bagages disponibles. C'est simple, rapide et économique. */}
                    </Typography>
                    {/* <h1 className='text-3xl font-semibold mb-4'>Vous souhaitez envoyer des bagages ou vendre vos kilos de bagages <span className="text-primary">simplement ?</span></h1> */}
                    {/* <p className="mb-4 pb-2">Avec SendNous, vous pouvez envoyer vos bagages par avion ou vendre vos kilos de bagages disponibles. C'est simple, rapide et économique. </p> */}
                </div>
            </div>
            <div className="md:flex md:flex-row md:flex-1">
                <div className="md:flex-1 m-5 mt-0">
                    <video className="w-full h-full rounded" controls autoPlay='' poster={process.env.PUBLIC_URL + "/logo.png"}>
                        <source
                            // src="https://docs.material-tailwind.com/demo.mp4" 
                            src={process.env.PUBLIC_URL + "/video_home.mp4"}
                            type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="md:flex-1 bg-white rounded mx-5 p-7 shadow-lg border">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        className="flex flex-col justify-between h-full"
                    >
                        <div className="mb-5">
                            <FormControl fullWidth>
                                <InputLabel id="type-label">Type de l'annonce</InputLabel>
                                <Select
                                    labelId="type-label"
                                    id="type"
                                    label="Type de l'annonce"
                                    value={adSearchRequest.type}
                                    onChange={(e) => updateAdSearch("type", e.target.value)}
                                >
                                    {[
                                        <MenuItem key={1} value={'SALE'}>
                                            Vente de kilos
                                        </MenuItem>
                                        ,
                                        <MenuItem key={2} value={'REQUEST'}>
                                            Demande d'achat de kilos
                                        </MenuItem>
                                    ]}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="mb-5">
                            <FormControl fullWidth>
                                <InputLabel id="departure-relay-label">Lieu de départ</InputLabel>
                                <Select
                                    labelId="departure-relay"
                                    id="departureRelay"
                                    value={adSearchRequest.departureRelay ? adSearchRequest.departureRelay : ''}
                                    label="Lieu de dépôt"
                                    onChange={(e) => updateAdSearch("departureRelay", e.target.value)}
                                >
                                    {relays && relays.map((relay) => [
                                        <MenuItem key={relay.id} value={relay}>
                                            {relay.city}, {relay.country}
                                        </MenuItem>
                                    ])}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="mb-5">
                            <FormControl fullWidth>
                                <InputLabel id="arrival-relay-label">Lieu d'arrivée</InputLabel>
                                <Select
                                    labelId="arrival-relay"
                                    id="arrivalRelay"
                                    value={adSearchRequest.arrivalRelay ? adSearchRequest.arrivalRelay : ''}
                                    label="Lieu de retrait"
                                    onChange={(e) => updateAdSearch("arrivalRelay", e.target.value)}
                                >
                                    {relays && relays.map((relay) => [
                                        <MenuItem key={relay.id} value={relay}>
                                            {relay.city}, {relay.country}
                                        </MenuItem>
                                    ])}
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <Button variant='contained' type='submit'
                                fullWidth sx={{ p: 1.5, fontWeight: 'bold', }}
                            >
                                Rechercher
                            </Button>
                        </div>
                        <div>
                            <Typography variant='body1' color='GrayText' align='center' sx={{ my: 2 }}>
                                Ou
                            </Typography>
                            <Button variant='outlined' onClick={() => navigate('/user/ads/published')}
                                fullWidth sx={{ p: 1.5, fontWeight: 'bold', }}
                            >
                                Créer une anonce
                            </Button>
                        </div>
                    </form>
                </div>
            </div>

        </div >
    )
}