import React, { useState } from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Footer from '../Layouts/Footer';
import { Button, Grid, Typography, useMediaQuery, useTheme, Box, CssBaseline } from '@mui/material';
import { useRole } from '../../../utils/UserProvider';
import NavBar from '../Layouts/NavBar';
import { MainDrawer, DrawerHeader } from '../Layouts/Drawer';

const AboutPage = ({ setLoading }) => {
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const { role } = useRole();

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            {(role === 'ADMIN' || role === 'COORDINATOR') && <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />}
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                    m: isMatch ? 0 : 5,
                    mt: 5,
                }}>
                    <DrawerHeader />
                    <Typography variant='h6' color='primary' gutterBottom>
                        À propos de nous
                    </Typography>
                    <Typography variant='h5' sx={{ mb: 3 }}>
                        Comment ça fonctionne
                    </Typography>
                    <Grid container spacing={4} sx={{ mb: 5 }} direction={isMatch ? 'row-reverse' : 'row'}>
                        <Grid item xs={12} md={6} textAlign='justify' sx={{ width: '90%' }}>
                            <Typography variant='h6' component='div' gutterBottom>
                                <ArrowCircleRightIcon color='primary' sx={{ mr: 1, mb: 1 }} fontSize='large' />
                                Notre service
                            </Typography>
                            <Typography variant='body1'>
                                SendNous est une plateforme en activité depuis 2024, il s'agit d'une solution pratique pour l'envoi de vos bagages par avion. <br />
                                Que vous ayez besoin d'expédier des bagages par avion ou que vous souhaitiez profiter de vos kilos de bagages inutilisés pour gagner de l'argent, notre plateforme web vous permet de vendre ou d'acheter des kilos de bagages en quelques clics.
                            </Typography>
                            <Typography variant='h6' component='div' gutterBottom sx={{ mt: 3 }}>
                                <ArrowCircleRightIcon color='primary' sx={{ mr: 1, mb: 1 }} fontSize='large' />
                                Notre Engagement
                            </Typography>
                            <Typography variant='body1'>
                                Nous nous engageons à vous procurer un service de qualité, ainsi qu'une tracabilité de vos bagages durant le parcours du depart jusqu'a la destination. <br />
                                Nous vérifions chaque compte utilisateur avant de permettre la publication d'annonces, et vos données personnelles sont traitées avec confidentialité. <br /><br />
                                Votre utilisation standard du site est entièrement gratuite.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} display='flex' alignItems='center' justifyContent='center'>
                            <img src={process.env.PUBLIC_URL + "/about.png"} alt="Service" style={{ width: '90%', height: 'auto', borderRadius: '8px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} sx={{ mb: 5 }} direction={isMatch ? 'row-reverse' : 'row'}>
                        <Grid item xs={12} md={6} textAlign='justify' sx={{ width: '90%' }}>
                            <Typography variant='h6' component='div' gutterBottom>
                                <ArrowCircleRightIcon color='primary' sx={{ mr: 1, mb: 1 }} fontSize='large' />
                                Comment utiliser l'application
                            </Typography>
                            <Typography variant='body1'>
                                Il suffit de se connecter ou si vous n'avez pas encore de compte de vous inscrire en cliquant sur les boutons/liens prevus à cet effet.
                                Vous pourrez ensuite acceder a votre compte, creer et modifier vos annonces et offres d'achat ou de vente de kilos.
                                Vous pouvez suivre l'avancée de votre/vos bagage(s) depuis la page de details de vos annonces ou offres.
                                Pour plus de details, visionnez la vidéo ci-après :
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} display='flex' alignItems='center' justifyContent='center'>
                                <video className="w-full h-full rounded" controls autoPlay='' poster={process.env.PUBLIC_URL + "/logo.png"}>
                                    <source 
                                        src={process.env.PUBLIC_URL + "/explication_sendnous.mp4"}
                                        type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                        </Grid>
                    </Grid>
                    <Box textAlign='center'>
                        <Button variant='contained' href='/contact' sx={{ mb: 3 }}>
                            Contactez-nous
                        </Button>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}

export default AboutPage