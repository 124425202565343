import React, { useEffect, useRef, useState } from 'react'
import { Button, Box, Card, CardContent, CardHeader, Chip, CssBaseline, Divider, Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Stack } from '@mui/material'
import Footer from '../../public_components/Layouts/Footer'
import { getAdById, updateAdProperties } from '../../../services/adService'
import { CalendarToday, Cancel, CheckCircle, DateRange, Sms, Today } from '@mui/icons-material'
import dayjs from 'dayjs'
import { statusColor, statusLabel } from '../../../data/adData'
import AdEditDialogUser from './AdEditDialog'
import { useRole } from '../../../utils/UserProvider'
import AdEditDialog from '../../coordinator_components/AdCrud/AdEditDialog'
import { MainDrawer, DrawerHeader } from '../../public_components/Layouts/Drawer';
import NavBar from '../../public_components/Layouts/NavBar';
import { getOfferById, getOffersByAd, updateOfferStatus } from '../../../services/offerService'
import { useJwt } from '../../../utils/UserProvider';

const AdView = ({ setLoading }) => {
    const { jwt } = useJwt();
    const [open, setOpen] = useState(false);
    const { role } = useRole();

    const adId = window.location.href.split("/user/ads/")[1];
    const url = '/user/ads/' + adId;
    const adIdRef = useRef();

    const [ad, setAd] = useState({
        title: '',
        type: '',
        picture: '',
        weight: 23,
        price: 1400,
        note: '',
        departureRelay: null,
        arrivalRelay: null,
        depositDeadline: dayjs('', "YYYY-MM-DD HH:mm"),
        departureDate: '',
        arrivalDate: '',
        stopoversDetails: '',
        luggageNumber: 0,
        luggageDescription: '',
        minDate: '',
        maxDate: '',
        adContentIds: [],
    });
    const [offers, setOffers] = useState([]);
    const [openUpdateAd, setOpenUpdateAd] = useState(false);

    useEffect(() => {
        const fetchAd = async () => {
            setLoading(true);
            const adData = await getAdById(adId, jwt);
            if (adData !== null) {
                setAd(adData);
            }
            setLoading(false);
        };

        if (adId && jwt) {
            fetchAd();
        }

    }, [jwt, adId, setLoading]);


    useEffect(() => {
        const fetchOffers = async () => {
            setLoading(true);
            const responseData = await getOffersByAd(adId, jwt);
            if (responseData !== null) {
                setOffers(responseData);
            }
            setLoading(false);
        };
        if (adId && jwt) {
            fetchOffers();
        }
    }, [jwt, adId, setLoading]);

    const handleClickUpdateAd = (id) => {
        adIdRef.current = id;
        setOpenUpdateAd(true);
    };

    const handleAcceptOffer = async (id) => {
        setLoading(true);
        const offer = await getOfferById(id, jwt);
        var newOffers = offers;
        if (offer !== null && offers.length > 0) {
            newOffers = offers.map(async (o) => {
                if (offer.bidder.id !== o.bidder.id)
                    o.offerStatus = 'REFUSED';
                const responseData = await updateOfferStatus(o, jwt);
                if (responseData !== null) {
                    return o;
                }
                else {
                    return null;
                }
            })
            if (!newOffers.includes(null)) {
                offer.offerStatus = 'ACCEPTED';
                const responseData = await updateOfferStatus(offer, jwt);
                if (responseData !== null) {
                    const adResponse = await updateAdProperties(ad.id, { 'status': 'RESPONDED', 'customer': offer.bidder }, jwt);
                    if (adResponse !== null) {
                        setOffers(newOffers);
                        setAd({ ...adResponse });
                    }
                }
            }
        }
        setLoading(false);
    }

    const handleRejectOffer = async (id) => {
        setLoading(true);
        const offer = await getOfferById(id, jwt);
        if (offer !== null) {
            offer.offerStatus = 'REFUSED';
            const responseData = updateOfferStatus(offer, jwt);
            if (responseData !== null) {
                setOffers(prev => {
                    const index = prev.findIndex((item) => item.id === offer.id);
                    prev = prev.map((o, i) => {
                        if (i === index)
                            return offer;
                        else
                            return o;
                    })
                    return prev;
                })
            }
        }
        setLoading(false);
    }

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <NavBar setLoading={setLoading} open={open} setOpen={setOpen} />
            <MainDrawer setLoading={setLoading} open={open} setOpen={setOpen} />
            <Box sx={{ flexGrow: 1 }}>
                <Box component="main" sx={{ p: 3, mt: 3 }}>
                    <DrawerHeader />
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                borderRadius: 3,
                                px: 4,
                                py: 2,
                                m: 2,
                            }}
                        >
                            <CardHeader
                                title={role !== 'USER' ? `Détails de l'annonce ${ad.id}` : `Détails de l'annonce`}
                                action={((ad.status === 'PENDING_VALIDATION' && role === 'USER')
                                    || (ad.status !== 'CLOSED' && role !== 'USER'))
                                    &&
                                    <Button variant='contained'
                                        onClick={() => {
                                            handleClickUpdateAd(ad.id)
                                        }}
                                    >
                                        Modifier
                                    </Button>
                                }
                            />
                            <Divider />
                            <CardContent>
                                <Grid container spacing={3} alignItems='center'>
                                    <Grid item xs={6} sm={3}>
                                        <img className='w-[5rem] h-[5rem] object-cover object-top'
                                            src={ad.picture} alt='' />
                                        <Typography variant='h6' component='h6'>
                                            {ad.type === 'SALE' ? `Vente` : `Demande`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <div>
                                            <CalendarToday color='primary' sx={{ height: 20 }} />
                                            <span className='text-sm'>Créée le {ad.creationDate}</span>
                                        </div>
                                        {ad.status !== 'PENDING_VALIDATION' && ad.status !== 'CANCELLED' &&
                                            <div>
                                                <Today color='primary' sx={{ height: 20 }} />
                                                <span className='text-sm'>Publiée le {ad.publicationDate && dayjs(ad.publicationDate).format('YYYY-MM-DD HH:MM')}</span>
                                            </div>
                                        }
                                        <div>
                                            <Sms color='primary' sx={{ height: 20 }} />
                                            <span className='text-sm'>{ad.offersNumber} réponses</span>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='body2' color='primary' fontWeight='bold'>
                                            {ad.price} MAD  / {ad.price * 60} Fcfa
                                        </Typography>
                                        <Typography variant='body2' color='primary' fontWeight='bold'>
                                            {ad.weight} KG
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        {ad.status &&
                                            <Chip
                                                label={statusLabel(ad.status)}
                                                variant="outlined"
                                                color={statusColor(ad.status)}
                                            />
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider orientation="horizontal" flexItem />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='h6' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            <span className='text-sm font-normal text-gray-600'>Titre</span>
                                            {ad.title}
                                        </Typography>
                                    </Grid>
                                    {role !== 'USER' &&
                                        <>
                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" flexItem />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                    Annonceur
                                                </Typography>
                                                {ad.publisher &&
                                                    <div>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Nom d'utilisateur</span>
                                                            {ad.publisher.username}
                                                        </Typography>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Nom & Prénom</span>
                                                            {ad.publisher.lastName} {ad.publisher.firstName}
                                                        </Typography>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Téléphone</span>
                                                            {ad.publisher.phone}
                                                        </Typography>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Email</span>
                                                            {ad.publisher.email}
                                                        </Typography>

                                                    </div>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                    Client
                                                </Typography>
                                                {ad.customer &&
                                                    <div>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Nom d'utilisateur</span>
                                                            {ad.customer.username}
                                                        </Typography>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Nom & Prénom</span>
                                                            {ad.customer.lastName} {ad.publisher.firstName}
                                                        </Typography>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Téléphone</span>
                                                            {ad.customer.phone}
                                                        </Typography>
                                                        <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                            <span className='text-sm text-gray-600'>Email</span>
                                                            {ad.customer.email}
                                                        </Typography>

                                                    </div>
                                                }
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider orientation="horizontal" flexItem />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                            Lieu de dépôt
                                        </Typography>
                                        {ad.departureRelay &&
                                            <div>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Pays</span>
                                                    {ad.departureRelay.country}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Ville</span>
                                                    {ad.departureRelay.city}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Addresse</span>
                                                    {ad.departureRelay.address}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Téléphone</span>
                                                    {ad.departureRelay.phone}
                                                </Typography>

                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                            Lieu de retrait
                                        </Typography>
                                        {ad.departureRelay &&
                                            <div>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Pays</span>
                                                    {ad.arrivalRelay.country}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Ville</span>
                                                    {ad.arrivalRelay.city}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Addresse</span>
                                                    {ad.arrivalRelay.address}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Téléphone</span>
                                                    {ad.arrivalRelay.phone}
                                                </Typography>

                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider orientation="horizontal" flexItem />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                            Dates <DateRange sx={{ height: 20 }} color='primary' />
                                        </Typography>
                                        {ad.type === 'REQUEST' &&
                                            <>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Minimum</span>
                                                    {ad.minDate}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Maximum</span>
                                                    {ad.maxDate}
                                                </Typography>
                                            </>
                                        }
                                        {ad.type === 'SALE' &&
                                            <>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Départ</span>
                                                    {ad.departureDate}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Arrivée</span>
                                                    {ad.arrivalDate}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Délai de dépot au relai</span>
                                                    {ad.depositDeadline}
                                                </Typography>
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                            Bagages
                                        </Typography>
                                        {ad.type === 'REQUEST' &&
                                            <>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Nombre</span>
                                                    {ad.luggageNumber}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Description</span>
                                                    {ad.luggageDescription}
                                                </Typography>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Contenu des bagages</span>
                                                    {ad.adContents && ad.adContents.map((content) =>
                                                        <span key={content.id}>
                                                            {content.designation}
                                                            {ad.adContents[ad.adContents.length - 1].id !== content.id && `, `}
                                                        </span>
                                                    )}
                                                </Typography>
                                            </>
                                        }
                                        {ad.type === 'SALE' &&
                                            <>
                                                <Typography variant='body1' component='span' sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    <span className='text-sm text-gray-600'>Nombre max de bagages</span>
                                                    {ad.luggageNumber}
                                                </Typography>

                                                {ad.stopoversDetails &&
                                                    <>
                                                        <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                            Escales
                                                        </Typography>
                                                        <Typography variant='body2'>
                                                            {ad.stopoversDetails}
                                                        </Typography>
                                                    </>
                                                }
                                            </>

                                        }
                                    </Grid>
                                    {ad.note &&
                                        [
                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" />
                                            </Grid>
                                            ,
                                            <Grid item xs={12} >
                                                <Typography variant='h6' component='h6' sx={{ mb: 1 }} >
                                                    Note
                                                </Typography>
                                                <Typography variant='body2' component='span' color='GrayText'>{ad.note}</Typography>
                                            </Grid>

                                        ]
                                    }
                                    <Grid item xs={12}>
                                        <Divider orientation="horizontal" />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography sx={{ my: 2 }} variant="h5" component="div">
                                            Offres
                                        </Typography>
                                        <Table sx={{ maxWidth: 550 }} aria-label="List of offers">
                                            <TableHead>
                                                <TableRow key={0}>
                                                    {ad.type === 'SALE' &&
                                                        [
                                                            <TableCell key={1}>Nombre de bagages</TableCell>
                                                            ,
                                                            <TableCell key={2}>Description</TableCell>
                                                            ,
                                                            <TableCell key={3}>Contenus</TableCell>

                                                        ]
                                                    }
                                                    {ad.type === 'REQUEST' &&
                                                        [
                                                            <TableCell key={4}>Départ</TableCell>
                                                            ,
                                                            <TableCell key={5}>Arrivée</TableCell>
                                                            ,
                                                            <TableCell key={6}>Délai de dépôt</TableCell>
                                                        ]
                                                    }
                                                    {ad.status === 'PUBLISHED' &&
                                                        <TableCell key={7}>Actions</TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {offers &&
                                                    (offers.length === 0 ?
                                                        <TableRow key={0}>
                                                            <TableCell key={1} colSpan={7} align='center' height={100}>
                                                                <Typography variant='body2' component='span' color='GrayText'>
                                                                    Aucune réponse
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        :
                                                        offers.map((offer) => {
                                                            return (
                                                                <TableRow
                                                                    key={offer.id}
                                                                    sx={{
                                                                        backgroundColor: offer.offerStatus === 'ACCEPTED' && '#c8e6c9',
                                                                        '&:last-child td, &:last-child th': { border: 0 }
                                                                    }}
                                                                >
                                                                    {ad.type === 'SALE' &&
                                                                        [
                                                                            <TableCell key={1}>
                                                                                {offer.luggageNumber}
                                                                            </TableCell>
                                                                            ,
                                                                            <TableCell key={2}>
                                                                                {offer.luggageDescription}
                                                                            </TableCell>
                                                                            ,
                                                                            <TableCell key={3}>
                                                                                <Stack sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                                                    {offer.offerContents && offer.offerContents.map((content) =>
                                                                                        <Typography key={content.id} variant='body2' component='span'>
                                                                                            {content.designation}
                                                                                        </Typography>
                                                                                    )}
                                                                                </Stack>
                                                                            </TableCell>

                                                                        ]
                                                                    }
                                                                    {ad.type === 'REQUEST' &&
                                                                        [
                                                                            <TableCell key={4}>
                                                                                {offer.departureDate}
                                                                            </TableCell>
                                                                            ,
                                                                            <TableCell key={5}>
                                                                                {offer.arrivalDate}
                                                                            </TableCell>
                                                                            ,
                                                                            <TableCell key={6}>
                                                                                {offer.depositDeadline}
                                                                            </TableCell>
                                                                        ]
                                                                    }
                                                                    <TableCell key={7}>
                                                                        {offer.offerStatus === 'PENDING_RESPONSE' &&
                                                                            [

                                                                                <IconButton edge="end" aria-label="Modifier"
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        handleAcceptOffer(offer.id);
                                                                                    }}
                                                                                    sx={{ mr: 0.25 }}
                                                                                >
                                                                                    <CheckCircle color='success' />
                                                                                </IconButton>
                                                                                ,
                                                                                <IconButton edge="end" aria-label="Supprimer" color='error'
                                                                                    onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        handleRejectOffer(offer.id);
                                                                                    }}
                                                                                >
                                                                                    <Cancel color='error' />
                                                                                </IconButton>
                                                                            ]
                                                                        }
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        })
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card >

                    </Grid>
                </Box>
                {adIdRef.current &&
                    role === 'USER' ?
                    <AdEditDialogUser setLoading={setLoading} adId={adIdRef.current} ad={ad} setAd={setAd} url={url}
                        openUpdateAd={openUpdateAd} setOpenUpdateAd={setOpenUpdateAd} />
                    :
                    <AdEditDialog setLoading={setLoading} adId={adIdRef.current} url={url}
                        openUpdateAd={openUpdateAd} setOpenUpdateAd={setOpenUpdateAd} />

                }
                {role !== 'ADMIN' && role !== 'COORDINATOR' && <Footer />}
            </Box>
        </Box>
    );
}

export default AdView