import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useJwt } from '../../../utils/UserProvider';
import showAlert from '../../../utils/ShowAlert'

const DeleteDialog = ({ setLoading, open, setOpen, id, deleteMethod, setData }) => {
    const { jwt } = useJwt();

    const handleClose = () => {
        setOpen(false);
    }

    const handleDelete = async () => {
        setLoading(true);
        if (id && jwt) {
            const data = await deleteMethod(id, jwt);
            if (data !== null) {
                setData(prev => prev.filter((item) => item.id !== id));
            }
        }
        setLoading(false);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            component='form'
            onSubmit={(e) => {
                e.preventDefault();
                handleDelete();
                handleClose();
            }}
        >
            <DialogTitle>
                Confirmer la suppression
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Êtes-vous sûr de vouloir supprimer ?
                    Cette action ne peut être annulée.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='inherit' onClick={() => { setOpen(false) }}>Annuler</Button>
                <Button variant='contained' color='error' type='submit' autoFocus>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog
